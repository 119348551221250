<template>
  <div>
    <div class="transdata_wrap radius-8">
      <div class="head_wrap p-t-b-15 p-l-r-30 flex justify-between">
        <div class="left">品类交易排行榜</div>
        <div class="right">
          <el-switch
            @change="changeDate"
            v-model="dateSwich"
            active-text="月"
            inactive-text="日"
          >
          </el-switch>
        </div>
      </div>
      <div class="head_wrap1 p-t-20 p-l-20">
        <el-switch
          @change="changOrder"
          v-model="orderBySwiche"
          active-text="交易笔数"
          inactive-text="交易金额"
        >
        </el-switch>
      </div>
      <div class="con_wrap">
        <div :id="idName" :style="{ height: height, width: width }" />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
export default {
  props: {
    className: {
      type: String,
      default: "chart",
    },
    idName: {
      type: String,
      default: "myPieChart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: 400 - 40 + "px",
    },
  },
  data() {
    return {
      chart: null,
      dateSwich: false,
      orderBySwiche: false,
      chartData: [],
      form: {
        dateType: 0,
        orderByType: 0,
      },
    };
  },

  watch: {
    chartData: {
      handler(val) {
        if (!this.$utils.isEmpty(val)) {
          this.initChart();
        }
      },
      deep: true,
    },
  },
  created() {
    // this.geyTransactionTotalTypeRank();
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    // 初始化图表
    initChart() {
      var chartDom = document.getElementById(this.idName);
      this.chart = echarts.init(chartDom, "macarons");
      this.setOptions(this.chartData);
    },
    // 配置数据
    setOptions() {
      let option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        // legend: {},
        series: [
          {
            name: this.orderByType == 0 ? "交易金额" : "交易笔数",
            type: "pie",
            radius: ["25%", "50%"],
            labelLine: {
              length: 50,
            },
            label: {
              formatter: " {b|{b}：}\n{c}   ",
              borderWidth: 1,
              borderRadius: 4,

              rich: {
                a: {
                  color: "#6E7079",
                  lineHeight: 22,
                  align: "center",
                },

                b: {
                  color: "#333",
                  fontSize: 14,
                  fontWeight: "bold",
                  lineHeight: 22,
                },
                per: {
                  color: "#fff",
                  backgroundColor: "#4C5058",
                  padding: [3, 4],
                  borderRadius: 4,
                },
              },
            },
            data: this.chartData,
          },
        ],
      };

      this.chart.setOption(option);
    },
    // 日期选择
    changeDate(e) {
      if (e) {
        this.form.dateType = 1;
      } else {
        this.form.dateType = 0;
      }
      this.geyTransactionTotalTypeRank();
    },
    // 分类选择
    changOrder(e) {
      if (e) {
        this.form.orderByType = 1;
      } else {
        this.form.orderByType = 0;
      }
      this.geyTransactionTotalTypeRank();
    },
    async geyTransactionTotalTypeRank() {
      const { form } = this;
      let result = await api.common.get({
        apiName: "/system/index/transactionTotalTypeRank",
        ...form,
      });
      let list = result.data;
      this.chartData = list.map((x) => {
        return {
          name: x.title,
          value: form.orderByType > 0 ? x.number : x.money,
        };
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>